import React, { Component } from "react";

import ContentLoader from "react-content-loader";
import LazyLoad from "react-lazyload";
import { NavLink } from "react-router-dom";
import Ink from "react-ink";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

class MainHomePromoSlider extends Component {

	constructor(props) {
		super(props);
	
		this.state = {
		  currentSlide: props.initialIndex || 0, // Set initial slide index
		};
	  }
	
	  handleBeforeChange = (oldIndex, newIndex) => {
		this.setState({ currentSlide: newIndex });
		if (this.props.onSlideChange) {
		  this.props.onSlideChange(newIndex); // Notify the parent component of the slide change
		}
	  };

	render() {
		const { slides, size, secondarySlider } = this.props;
        const totalSlides = slides.length;
		const responsive = {
			desktop: {
			  breakpoint: { max: 3000, min: 1024 },
			  items: 3,
			  partialVisibilityGutter: 30
			},
			tablet: {
			  breakpoint: { max: 1024, min: 464 },
			  items: 2,
			  partialVisibilityGutter: 30
			},
			mobile: {
			  breakpoint: { max: 464, min: 300 },
			  items: 1,
			  partialVisibilityGutter: 60
			},
		};
        
        // const CustomDot = ({ index, onClick, active }) => (
		// 	<div onClick={() => onClick()} className="custom-dot-container">
		// 	  {active ? (
		// 		<span style={{ backgroundColor: '#D60265' }}>{`${index + 1}/${totalSlides}`}</span>
		// 	  ) : (
		// 		<span>{`${index + 1}/${totalSlides}`}</span>
		// 	  )}
		// 	</div>
		//   );

		const CustomDot = ({ index, onClick, active }) => (
			<div onClick={() => onClick()} className="custom-dot-container" style={{ marginTop: "20px", }}>
			  {active ? (
				// Active dot: display index/totalSlides with custom styles
				<span className="_3__hC" style={{ backgroundColor: 'black', fontSize: '10px', color: 'white', padding: '5px', borderRadius: '50%' }}>
				  {`${index + 1}/${totalSlides}`}
				</span>
			  ) : (
				// Inactive dot: simple dot or circle
				<span style={{
				  display: 'inline-block',
				  width: '10px',
				  height: '10px',
				  backgroundColor: '#ccc',
				  borderRadius: '50%',
				  margin: '0 5px'
				}}></span>
			  )}
			</div>
		  );
		  
		return (
			<React.Fragment>
				{slides.length === 0 ? (
				<ContentLoader
					height={220}
					width={380}
					speed={1.2}
					primaryColor="#f3f3f3"
					secondaryColor="#ecebeb"
				>
					<rect x="20" y="4" rx="16" ry="16" width="312" height="180" /> 
					<rect x="347" y="3" rx="16" ry="16" width="312" height="180" />
				</ContentLoader>
				):(
				<React.Fragment>
					<Carousel
						additionalTransfrom={0}
						swipeable={true}
						arrows={false}
						autoPlay={true}
						autoPlaySpeed={5000}
						centerMode={false}
						className={""}
						containerClass={""}
						customTransition={"all .5s linear"}
						dotListClass={""}
						draggable={true}
						focusOnSelect={false}
						infinite={true}
						itemClass={""}
						keyBoardControl={true}
						minimumTouchDrag={20}
						partialVisible={true}
						pauseOnHover={true}
						renderArrowsWhenDisabled={false}
						renderButtonGroupOutside={false}
						renderDotsOutside={true}
						responsive={responsive}
						rewind={false}
						rewindWithAnimation={false}
						rtl={false}
						shouldResetAutoplay={true}
						showDots
						sliderClass={""}
                        customDot={<CustomDot />}
						beforeChange={this.handleBeforeChange}
						>
						{slides.map((slide) => slide && (
							<a key={slide.data.id} className="banner-carousel--banner full" href={slide.url}>
								<div className="image-overlay">
								<div className="image-overlay--overlay "></div>
								<img src={slide.data.image} alt="Banner" />
								</div>
							</a>
						))}
					</Carousel>
				</React.Fragment>
				)}
				
			</React.Fragment>
		);
	}
}

export default MainHomePromoSlider;
